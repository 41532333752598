<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useApi } from "@/features/Api/useApi";
import dayjs from "dayjs";
import ChartJS from "@/components/ChartJS.vue";
import { useFilters } from "@/composables/filters/useFilters";

const dataRef = ref({});
const loadingRef = ref(false);
defineEmits(["onBarClick"]);

const props = defineProps({
  emptyMessage: {
    type: String,
    default: "No deals found with these filters",
  },
  endpoint: {
    type: String,
    default: "/deals/users",
  },
  barOne: {
    type: String,
    default: "",
  },
  barOnePercentage: {
    type: Boolean,
    default: false,
  },
  barTwo: {
    type: String,
    default: "",
  },
  barOneTotalLabel: {
    type: String,
    default: "",
  },
  barTwoTotalLabel: {
    type: String,
    default: "",
  },
  barOneTitle: {
    type: String,
    default: "",
  },
  barTwoTitle: {
    type: String,
    default: "",
  },
});
const {
  timestampCreated,
  filterTypeCreated,
  timestampClosed,
  filterTypeClosed,
  selectedUsers,
  selectedTeams,
  selectedDealPases,
  selectedDealSources,
} = useFilters();
const getPercentage = (one, two) => {
  return Math.round((one / two) * 100);
};
const api = useApi();
const query = async (
  time_created,
  type_created,
  time_closed,
  type_closed,
  users,
  teams,
  deal_phases,
  deal_sources
) => {
  let params = {};
  if (time_created && type_created) {
    const datetime_created = dayjs(time_created);
    params["created_at_after"] = datetime_created.startOf(type_created).unix();
    params["created_at_before"] = datetime_created.endOf(type_created).unix();
  }
  if (time_closed && type_closed) {
    const datetime_closed = dayjs(time_closed);
    params["closed_at_after"] = datetime_closed.startOf(type_closed).unix();
    params["closed_at_before"] = datetime_closed.endOf(type_closed).unix();
  }
  if (users?.length > 0) {
    params.users = users.join(",");
  }
  if (teams?.length > 0) {
    params.teams = teams.join(",");
  }
  if (deal_phases?.length > 0) {
    params.deal_phases = deal_phases.join(",");
  }
  if (deal_sources?.length > 0) {
    params.deal_sources = deal_sources.join(",");
  }
  return await api.get(props.endpoint, {
    params,
  });
};
onMounted(() => {
  if (loadingRef.value) return;
  loadingRef.value = true;
  query(
    timestampCreated.value,
    filterTypeCreated.value,
    timestampClosed.value,
    filterTypeClosed.value,
    selectedUsers.value,
    selectedTeams.value,
    selectedDealPases.value,
    selectedDealSources.value
  )
    .then(({ data }) => {
      dataRef.value = data;
    })
    .finally(() => {
      loadingRef.value = false;
    });
});
watch(
  [
    timestampCreated,
    filterTypeCreated,
    timestampClosed,
    filterTypeClosed,
    selectedDealSources,
    selectedDealPases,
    selectedUsers,
    selectedTeams,
  ],
  () => {
    console.log(timestampCreated.value);
    if (loadingRef.value) return;
    loadingRef.value = true;
    query(
      timestampCreated.value,
      filterTypeCreated.value,
      timestampClosed.value,
      filterTypeClosed.value,
      selectedUsers.value,
      selectedTeams.value,
      selectedDealPases.value,
      selectedDealSources.value
    )
      .then(({ data }) => {
        dataRef.value = data;
      })
      .finally(() => {
        loadingRef.value = false;
      });
  }
);
const getColor = (percentage) => {
  if (percentage < 60) {
    return "#f44336";
  } else if (percentage < 100) {
    return "#F89C0C";
  } else {
    return "#4caf50";
  }
};
const barOneTotal = computed(() => {
  return props.barOne
    ? dataRef.value?.[props.barOne]?.reduce((a, b) => a + parseInt(b), 0)
    : 0;
});
const barTwoTotal = computed(() => {
  return props.barTwo
    ? dataRef.value?.[props.barTwo]?.reduce((a, b) => a + parseInt(b), 0)
    : 0;
});
const caseSumChart = computed(() => ({
  labels: dataRef.value?.labels || [],
  datasets: [
    ...(props.barOne
      ? [
          {
            label: props.barOneTitle,
            data: dataRef.value?.[props.barOne] || [],
            barPercentage: 0.5,
            categoryPercentage: 0.85,
            backgroundColor: "rgba(114,114,114,0.7)",
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "red",
            barOneTotal: barOneTotal.value,
          },
        ]
      : []),
    ...(props.barTwo
      ? [
          {
            label: props.barTwoTitle,
            data: dataRef.value?.[props.barTwo] || [],
            // data: [65, 59, 80, 81, 56, 55, 40],
            // barThickness: 30,
            // barPercentage:0.5,
            barPercentage: 0.5,
            categoryPercentage: 0.85,
            backgroundColor: dataRef?.value?.[props.barTwo]
              ? dataRef.value?.[props.barTwo].map((value, index) => {
                  const percentage = getPercentage(
                    value,
                    dataRef.value?.[props.barOne][index]
                  );
                  return getColor(percentage);
                })
              : "grey",
            percentages: dataRef?.value?.[props.barTwo]
              ? dataRef.value?.[props.barTwo].map((value, index) => {
                  return getPercentage(
                    value,
                    dataRef.value?.[props.barOne][index]
                  );
                })
              : "grey",
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "red",
          },
        ]
      : []),
  ],
}));
</script>

<template>
  <ChartJS
    :bar-one-total-label="barOneTotalLabel"
    :bar-two-total-label="barTwoTotalLabel"
    :bar-one-total="barOneTotal"
    :bar-two-total="barTwoTotal"
    :empty-message="emptyMessage"
    :chart-data="caseSumChart"
    @on-bar-click="(e) => $emit('onBarClick', e)"
  />
</template>

<style scoped lang="scss"></style>
