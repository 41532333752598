import { defineStore } from "pinia";
import { ref } from "vue";
import { useApi } from "@/features/Api/useApi";

export const useDealSource = defineStore(
  "useDealSource",
  () => {
    const api = useApi();

    const deal_sources = ref([]);
    const loading = ref(false);
    const loadDealSources = async () => {
      if (loading.value) {
        return;
      }
      loading.value = true;
      await api
        .get("/deal-source")
        .then(({ data }) => {
          deal_sources.value = data;
        })
        .finally(() => (loading.value = false));
    };
    return {
      loadDealSources,
      deal_sources,
    };
  },
  {
    persist: [
      {
        paths: ["deal_sources"],
        storage: sessionStorage,
      },
    ],
  }
);
