<script setup>
import { onMounted, h, reactive, ref, watch } from "vue";
import { useApi } from "@/features/Api/useApi";
import { NButton, NSwitch, NDropdown, NIcon, useMessage } from "naive-ui";
import { PaperPlane, Download, Edit } from "@vicons/fa";
import logo from "@/assets/logo_dark.png";
import jsPDF from "jspdf";
import { debounce } from "lodash/function";
import { useI18n } from "vue-i18n";

const manualAgreementModal = ref(false);
const editItem = ref(null);
const iban = ref("");

const dataRef = ref([]);
const loadingRef = ref(true);
const loadingUpdate = ref(false);
const api = useApi();
const rowKey = (rowData) => {
  return rowData.company_id;
};
const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};

const sendAgreement = (row) => {
  console.log(import.meta.env.VITE_URL);
  api
    .post(`/companies/sent-agreement/${row.company_id}`, {
      domain: import.meta.env.VITE_URL,
    })
    .then(
      (res) => {
        message.success("Email has been sent");
        console.log(res);
      },
      (error) => {
        message.error("Something went wrong");
        console.log(error);
      }
    );
};
const downLoadAgreement = (row) => {
  try {
    const doc = new jsPDF();
    const image = new Image();
    image.src = logo;
    // Add image to PDF
    doc.addImage(image, "JPEG", 10, 15, 86.5, 20);

    doc.setFont("Helvetica", "bold");
    doc.setTextColor(254, 156, 8); // red
    doc.text("Name:", 10, 50);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // black
    doc.text(row.name, 40, 50);
    // Add text to PDF
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(254, 156, 8); // red
    doc.text("Agreed:", 10, 60);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // black
    doc.text(row.granted ? "yes" : "no", 40, 60);

    doc.setFont("Helvetica", "bold");
    doc.setTextColor(254, 156, 8); // red
    doc.text("IBAN:", 10, 70);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // black
    doc.text(row?.iban || "-", 40, 70);

    doc.setFontSize(14);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // black
    doc.text(
      `This document serves as proof of the agreement made between ${row.name} and Linqo for the automatic collection of payments via the International Bank Account Number (IBAN) provided at the top of this document.`,
      10,
      80,
      { maxWidth: 190 }
    );
    doc.text(
      `As a reminder, by signing the original agreement, ${row.name} acknowledged and agreed to the following terms:`,
      10,
      100,
      { maxWidth: 190 }
    );
    doc.text(
      "- Payments will be collected automatically from the IBAN provided on a recurring basis, as specified in the original agreement.",
      15,
      115,
      { maxWidth: 185 }
    );
    doc.text(
      "- The amount and frequency of these payments will be determined by the terms agreed upon in the original agreement.",
      15,
      130,
      { maxWidth: 185 }
    );
    doc.text(
      "- If any changes need to be made to the payment schedule or amount, both parties will be notified and agreement will be reached before any changes are made.",
      15,
      150,
      { maxWidth: 185 }
    );
    doc.text(
      "This document serves as the signed and agreed-upon version of the original agreement and will be used as proof of consent and authorization for automatic collection of payments.",
      10,
      165,
      { maxWidth: 190 }
    );
    doc.text(
      "Thank you for your cooperation and please don't hesitate to contact us if you have any questions or concerns.",
      10,
      185,
      { maxWidth: 190 }
    );
    // Save PDF
    doc.save("document.pdf");
  } catch (e) {
    console.log(e);
  }
};
const openPopup = (row) => {
  editItem.value = row;
  iban.value = row.iban;
  manualAgreementModal.value = true;
};

const pageSize = 50;
const columns = [
  {
    type: "selection",
  },
  {
    title: "Name",
    key: "name",
    width: 150,
  },
  {
    title: "Email",
    key: "email",
    width: 100,
  },
  {
    title: "Country",
    key: "country",
    width: 100,
  },
  {
    title: "City",
    key: "city",
    width: 100,
  },
  {
    title: "Postal code",
    key: "postal_code",
    width: 100,
  },
  {
    title: "IBAN",
    key: "iban",
    width: 100,
  },
  {
    title: "VAT",
    key: "vat_number",
    width: 100,
  },
  {
    title: "Granted",
    key: "granted",
    width: 100,
    render: (row) => {
      return h(NSwitch, {
        disabled: true,
        value: row.granted,
      });
    },
  },
  {
    title: "Actions",
    key: "actions",
    width: 100,
    render(row) {
      return h(
        NDropdown,
        {
          size: "small",
          options: [
            {
              label: "Send Direct Debit Form",
              key: "sendAgreement",
              icon: renderIcon(PaperPlane),
              props: {
                onClick: () => sendAgreement(row),
              },
            },
            {
              label: "Download Direct Debit Form",
              key: "editProfile",
              icon: renderIcon(Download),
              props: {
                onClick: () => downLoadAgreement(row),
              },
            },
            {
              label: "Enter manual Direct Debit Form",
              key: "enterManualAgreement",
              icon: renderIcon(Edit),
              props: {
                onClick: () => openPopup(row),
              },
            },
          ],
        },
        {
          default: () =>
            h(NButton, { size: "small" }, { default: () => "More ↓" }),
        }
      );
    },
  },
];

const query = async (page, pageSize = pageSize, search = "") => {
  let params = {
    page: page,
    pageSize: pageSize,
  };
  if (search) {
    params.search = search;
  }
  return await api.get("/companies", {
    params,
  });
};

const paginationReactive = reactive({
  page: 1,
  pageCount: 1,
  pageSize: pageSize,
  showQuickJumper: true,
  showSizePicker: true,
  pageSizes: [50, 100, 200],
  prefix: ({ itemCount }) => {
    return `Total is ${itemCount}.`;
  },
});

onMounted(() => {
  query(
    paginationReactive.page,
    paginationReactive.pageSize,
    searchValue.value
  ).then(({ data }) => {
    dataRef.value = data.data;
    paginationReactive.pageCount = data.meta.pageCount;
    paginationReactive.itemCount = data.meta.itemCount;
    loadingRef.value = false;
  });
});
const handlePageChange = (currentPage) => {
  if (!loadingRef.value) {
    loadingRef.value = true;
    query(currentPage, paginationReactive.pageSize, searchValue.value).then(
      ({ data }) => {
        dataRef.value = data.data;
        paginationReactive.page = currentPage;
        paginationReactive.pageCount = data.meta.pageCount;
        paginationReactive.itemCount = data.meta.itemCount;
        loadingRef.value = false;
      }
    );
  }
};

const handlePageSizeChange = (pageSize) => {
  if (!loadingRef.value) {
    loadingRef.value = true;
    query(paginationReactive.page, pageSize, searchValue.value).then(
      ({ data }) => {
        dataRef.value = data.data;
        paginationReactive.pageSize = pageSize;
        paginationReactive.pageCount = data.meta.pageCount;
        paginationReactive.itemCount = data.meta.itemCount;
        loadingRef.value = false;
      }
    );
  }
};
const message = useMessage();
const { t } = useI18n();

const segmented = {
  content: "soft",
  footer: "soft",
};
const bodyStyle = { width: "600px" };
const onPositiveClick = () => {
  if (loadingUpdate.value) {
    return;
  }
  loadingUpdate.value = true;
  console.log("positive");
  api
    .patch(`/companies/${editItem.value.company_id}`, {
      iban: iban.value,
      granted: true,
    })
    .then(() => {
      editItem.value = null;
      iban.value = "";
      manualAgreementModal.value = false;
      message.success("Updated");
      query(
        paginationReactive.page,
        paginationReactive.pageSize,
        searchValue.value
      ).then(({ data }) => {
        dataRef.value = data.data;
        paginationReactive.pageCount = data.meta.pageCount;
        paginationReactive.itemCount = data.meta.itemCount;
        loadingRef.value = false;
      });
    })
    .catch((e) => {
      console.log(e);
      message.error(
        t(`common.base.${e?.response?.status}`, "common.base.unkown_error")
      );
    })
    .finally(() => {
      loadingUpdate.value = false;
    });
};
const afterHide = () => {
  editItem.value = null;
  iban.value = "";
};
const searchValue = ref("");
const search = ref("");

const updateSearch = debounce((v) => (searchValue.value = v), 250);

watch(search, (val) => {
  // noinspection JSValidateTypes
  updateSearch(val);
});
watch([searchValue], () => {
  if (loadingRef.value) return;
  loadingRef.value = true;
  query(
    paginationReactive.page,
    paginationReactive.pageSize,
    searchValue.value
  ).then(({ data }) => {
    dataRef.value = data.data;
    paginationReactive.pageCount = data.meta.pageCount;
    paginationReactive.itemCount = data.meta.itemCount;
    loadingRef.value = false;
  });
});
const checkedRowKeysRef = ref([]);

const handleCheck = (rowKeys) => {
  checkedRowKeysRef.value = rowKeys;
};
const sentBulkAgreement = () => {
  if (checkedRowKeysRef.value.length < 1) {
    return;
  }
  api
    .post(`/companies/sent-bulk-agreement`, {
      domain: import.meta.env.VITE_URL,
      companies: checkedRowKeysRef.value,
    })
    .then(
      (res) => {
        message.success("Email has been sent");
        checkedRowKeysRef.value = 0;
        console.log(res);
      },
      (error) => {
        message.error("Something went wrong");
        console.log(error);
      }
    );
};
</script>

<template>
  <div class="users">
    <n-card>
      <h1 style="margin-top: 0">Customers</h1>
      <n-space vertical>
        <n-space>
          <n-button
            v-if="checkedRowKeysRef.length > 0"
            :render-icon="renderIcon(PaperPlane)"
            @click="sentBulkAgreement"
            >Send Bulk Direct Debit Form ({{
              checkedRowKeysRef.length
            }})</n-button
          >
          <n-input
            v-model:value="search"
            style="width: 300px"
            type="text"
            placeholder="Search by company name"
          />
        </n-space>
        <n-data-table
          ref="table"
          remote
          :checked-row-keys="checkedRowKeysRef"
          :columns="columns"
          :data="dataRef"
          :loading="loadingRef"
          :pagination="paginationReactive"
          :row-key="rowKey"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          @update:checked-row-keys="handleCheck"
        />
      </n-space>
    </n-card>
    <n-modal
      v-model:show="manualAgreementModal"
      class="custom-card"
      :style="bodyStyle"
      title="Agree to the authorization?"
      :bordered="false"
      size="huge"
      :mask-closable="false"
      preset="dialog"
      :segmented="segmented"
      content="Are you sure?"
      positive-text="Confirm"
      negative-text="Cancel"
      @positive-click="onPositiveClick"
      @after-hide="afterHide"
    >
      <n-input
        v-model:value="iban"
        :loading="loadingUpdate"
        placeholder="Enter IBAN"
      />
    </n-modal>
  </div>
</template>

<style scoped lang="scss">
.users {
  padding: 20px;
}
</style>
