import { ref, watch } from "vue";

export const createFilters = () => {
  const route = useRoute();
  const timestampCreated = ref(
    route?.query?.timestampCreated && !route?.query?.timestampClosed
      ? new Date(parseInt(route?.query?.timestampCreated))
      : null
  );
  const filterTypeCreated = ref("month");

  const timestampClosed = ref(
    route?.query?.timestampClosed && !route?.query?.timestampCreated
      ? new Date(parseInt(route?.query?.timestampClosed))
      : route?.query?.timestampCreated
      ? null
      : Date.now()
  );
  const filterTypeClosed = ref("month");

  const searchValue = ref("");
  const selectedUsers = ref(route?.query?.user ? [route.query.user] : null);
  const selectedTeams = ref(route?.query?.team ? [route.query.team] : null);
  const { deal_phases } = useDealPhase();

  const selectedDealPases = ref(
    deal_phases.filter((d) => d.name === "Accepted").map((d) => d.deal_phase_id)
  );
  const { deal_sources } = useDealSource();
  const selectedDealSources = ref([
    "unknown",
    ...deal_sources
      .filter((d) => d.name !== "Upsell")
      .map((d) => d.deal_source_id),
  ]);
  const search = ref("");

  const showAppropriateFilterCreated = (value) => {
    filterTypeCreated.value = value;
    timestampCreated.value = Date.now();
    timestampClosed.value = null;
  };

  const showAppropriateFilterClosed = (value) => {
    filterTypeClosed.value = value;
    timestampClosed.value = Date.now();
    timestampCreated.value = null;
  };

  const onCreateUpdate = (value) => {
    timestampCreated.value = value;
    timestampClosed.value = null;
  };

  const onClosedUpdate = (value) => {
    timestampClosed.value = value;
    timestampCreated.value = null;
  };

  const updateSearch = debounce((v) => (searchValue.value = v), 250);

  watch(search, (val) => {
    // noinspection JSValidateTypes
    updateSearch(val);
  });
  return {
    onCreateUpdate,
    onClosedUpdate,
    showAppropriateFilterClosed,
    showAppropriateFilterCreated,
    timestampClosed,
    timestampCreated,
    selectedTeams,
    selectedUsers,
    selectedDealPases,
    selectedDealSources,
    filterTypeCreated,
    filterTypeClosed,
    updateSearch,
    searchValue,
    search,
  };
};

import { inject, provide } from "vue";
import { debounce } from "lodash/function";
import { useDealSource } from "@/features/Deals/useDealSource";
import { useDealPhase } from "@/features/Deals/useDealPhase";
import { useRoute } from "vue-router";

const FiltersKey = Symbol("Filters");

export function provideFilters(props) {
  const inst = createFilters(props);
  provide(FiltersKey, inst);
  return inst;
}

export function useFilters() {
  const instance = inject(FiltersKey);

  if (!instance) {
    throw new Error("Run provideFilters before useFeature.");
  }

  return instance;
}
