<script setup>
import { useMessage } from "naive-ui";
import { reactive, ref } from "vue";
import LogoMain from "@/assets/logo.svg";
import { CreditCard } from "@vicons/fa";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { useRoute } from "vue-router";

const finished = ref(false);
const formRef = ref();
const message = useMessage();
const loading = ref(false);
const { query } = useRoute();

const formInline = reactive({
  iban: "",
  agreed: true,
});

const rules = {
  iban: {
    required: true,
    message: "Enter a valid IBAN",
    trigger: "blur",
  },
  agreed: {
    required: true,
    message: "Please agree",
    trigger: "blur",
  },
};

const { t } = useI18n();
function submit() {
  if (!query.token) {
    message.error("Token is missing");
    return;
  }
  formRef.value.validate(async (errors) => {
    if (errors) {
      message.error("Please enter all fields");
      return;
    }

    loading.value = true;

    const { iban } = formInline;
    axios
      .post(
        `${import.meta.env.VITE_API_URL}/companies/update-agreement`,
        { iban },
        {
          headers: {
            Authorization: `Bearer ${atob(query?.token)}`,
          },
        }
      )
      .then(() => {
        message.success("IBAN has been changed");
        finished.value = true;
      })
      .catch((e) => {
        message.error(
          t(`common.base.${e?.response?.status}`, "common.base.unkown_error")
        );
      })
      .finally(() => {
        loading.value = false;
      });
  });
}
</script>

<template>
  <div class="view-account">
    <div class="container">
      <div class="logo">
        <LogoMain />
      </div>
      <n-form
        v-if="!finished"
        ref="formRef"
        label-placement="left"
        size="large"
        :model="formInline"
        :rules="rules"
        class="form"
      >
        <n-form-item path="email">
          <n-input
            v-model:value="formInline.iban"
            placeholder="Please enter iban"
            @keydown.enter="submit"
          >
            <template #prefix>
              <n-icon size="18" color="#808695"> <CreditCard /> </n-icon>
            </template>
          </n-input>
        </n-form-item>
        <n-form-item path="password">
          <n-checkbox v-model:checked="formInline.password">
            I have authorized Linqo to initiate automatic withdrawals from my
            account.
          </n-checkbox>
        </n-form-item>
        <n-form-item>
          <n-button
            type="primary"
            size="large"
            :loading="loading"
            block
            @click="submit"
          >
            Submit
          </n-button>
        </n-form-item>
      </n-form>
      <n-alert v-else title="IBAN Changed" type="success">
        You can close this page successfully
      </n-alert>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.view-account {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-image: url("./login-bg.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  align-items: center;

  .container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 12px;
    max-width: 384px;
    min-width: 320px;
    gap: 25px;

    .form {
      width: 100%;
    }
  }
}
</style>
