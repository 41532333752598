<template>
  <div style="padding: 20px">
    <n-grid cols="1 l:3" responsive="screen" :x-gap="20">
      <n-grid-item>
        <n-card :bordered="false" size="small" class="proCard">
          <n-thing
            v-for="item in typeTabList"
            :key="item.key"
            class="thing-cell"
            :class="{ 'thing-cell-on': type === item.key }"
            @click="switchType(item)"
          >
            <template #header>{{ item.name }}</template>
            <template #description>{{ item.desc }}</template>
          </n-thing>
        </n-card>
      </n-grid-item>
      <n-grid-item>
        <n-card
          :bordered="false"
          size="small"
          :title="typeTitle"
          class="proCard"
        >
          <BasicSetting v-if="type === 1" />
        </n-card>
      </n-grid-item>
    </n-grid>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import BasicSetting from "./BasicSetting.vue";
import { useRoute, useRouter } from "vue-router";
import { useMessage } from "naive-ui";

const typeTabList = [
  {
    name: "Teamleader",
    desc: "Settings for teamleader",
    key: 1,
  },
];

const type = ref(1);
const typeTitle = ref("Teamleader Settings");
const message = useMessage();
const { query } = useRoute();

const success = ref(!!query?.success);

function switchType(e) {
  type.value = e.key;
  typeTitle.value = e.name;
}

const { replace } = useRouter();
watch(
  () => success.value,
  (v) => {
    if (v) {
      // Remove param because it does not exist in our store
      message.success("Connection success");
      success.value = false;
      return replace({ params: { success: null } });
    }
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
.n-layout-scroll-container {
  background: red !important;
}
.thing-cell {
  margin: 0 -16px 10px;
  padding: 5px 16px;

  &:hover {
    background: #f3f3f3;
    cursor: pointer;
  }
}

.thing-cell-on {
  background: #fff8f0;
  color: #ff9c0b;

  ::v-deep(.n-thing-main .n-thing-header .n-thing-header__title) {
    color: #ff9c0b;
  }

  &:hover {
    background: #fff8f0;
  }
}
</style>
