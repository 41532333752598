<script setup>
import { useUsersTarget } from "@/features/Users/useUsersTargetView";
import dayjs from "dayjs";

const { targets, year, updateValues } = useUsersTarget();
</script>
<template>
  <n-space vertical>
    <n-divider style="margin: 10px 0" />

    <n-space justify="space-between" align="center">
      <b>Filter by year</b>
      <n-date-picker v-model:value="year" type="year" />
    </n-space>
    <n-divider style="margin: 10px 0" />

    <n-space vertical style="gap: 10px">
      <template v-for="(target, index) in targets" :key="index">
        <n-form-item
          :label-width="200"
          label-align="left"
          label-placement="left"
          :label="dayjs.unix(target.month).format('MMMM')"
          path="inputValue"
        >
          <n-input-number
            v-model:value="target.value"
            style="width: 100%"
            placeholder="Input"
            @change="updateValues"
          />
        </n-form-item>
      </template>
    </n-space>
  </n-space>
</template>
<style>
.n-form-item-feedback-wrapper {
  display: none;
}
</style>
