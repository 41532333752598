<script setup>
import { onMounted, h, ref, computed } from "vue";
import { NTag, NButton, NSpace, NSelect, useMessage } from "naive-ui";
import { provideUsersTarget } from "@/features/Users/useUsersTargetView";
import { provideUsersAgreement } from "@/features/Users/useUsersAgreement";
import UsersTargetView from "@/features/Users/UsersTargetView.vue";
import UsersAgreementModal from "@/features/Users/UsersAgreementModal.vue";
import { useApi } from "@/features/Api/useApi";
import { useAuthStore } from "@/store/useAuthStore";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const loadingRef = ref(false);
const users = ref([]);
const api = useApi();

const rowKey = (rowData) => {
  return rowData.user_id;
};
const { openUsersTarget } = provideUsersTarget();
const { t } = useI18n();
const message = useMessage();
const authStore = useAuthStore();
const { openAgreementModal } = provideUsersAgreement();
const { user } = storeToRefs(authStore);
const isAllowed = computed(() => {
  return ["admin", "team_manager"].includes(user.value.role);
});
const columns = [
  {
    title: "First name",
    key: "first_name",
    width: 150,
  },
  {
    title: "Email",
    key: "email",
    width: 100,
  },
  {
    title: "Role",
    key: "role",
    width: 150,
    render: (row, index) => {
      return h(NSelect, {
        name: "role",
        value: row.role,
        disabled: user.value.role !== "admin",
        onUpdateValue: (value) => {
          let oldValue = users.value[index].role;
          users.value[index].role = value;
          console.log("calling debounce");
          api
            .patch(`/users/${row.user_id}`, {
              role: value,
            })
            .catch((e) => {
              console.log(e);
              message.error(
                t(
                  `common.base.${e?.response?.status}`,
                  "common.base.unkown_error"
                )
              );
              users.value[index].role = oldValue;
            });
        },
        options: [
          { label: "User", value: "user" },
          { label: "Admin", value: "admin" },
          { label: "CEO", value: "team_manager" },
          { label: "Sales Director", value: "team_leader" },
        ],
      });
    },
  },
  {
    title: "Function",
    key: "function",
    width: 150,
  },
  {
    title: "Team",
    key: "Teams",
    width: 100,
    render: (row) => {
      return row?.teams
        ? row?.teams?.map((team) => {
            return h(
              NTag,
              {
                style: {
                  marginRight: "6px",
                },
                type: "primary",
                bordered: false,
              },
              {
                default: () => team?.name,
              }
            );
          })
        : h("div", {}, { default: () => "-" });
    },
  },
  ...(isAllowed.value
    ? [
        {
          title: "Actions",
          key: "actions",
          width: 200,
          render(row) {
            return h(NSpace, null, {
              default: () => [
                h(
                  NButton,
                  {
                    size: "small",
                    onClick: () => openUsersTarget(row),
                    style: { marginBottom: 5 },
                  },
                  {
                    default: () => "Change targets",
                  }
                ),
                h(
                  NButton,
                  {
                    size: "small",
                    onClick: () => openAgreementModal(row),
                  },
                  {
                    default: () => "Change password",
                  }
                ),
              ],
            });
          },
        },
      ]
    : []),
];

const fetchUsers = async () => {
  if (loadingRef.value) return;
  loadingRef.value = true;
  await api
    .get("/users")
    .then(({ data }) => {
      users.value = data;
    })
    .finally(() => (loadingRef.value = false));
};

onMounted(() => {
  fetchUsers();
});
</script>

<template>
  <div class="users">
    <n-card>
      <h1 style="margin-top: 0">Users</h1>
      <n-space vertical>
        <n-data-table
          :columns="columns"
          :data="users"
          :row-key="rowKey"
          :pagination="false"
        />
      </n-space>
    </n-card>
    <UsersAgreementModal />
    <UsersTargetView />
  </div>
</template>

<style scoped lang="scss">
.users {
  padding: 20px;
}
</style>
