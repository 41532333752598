<script setup>
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import DealsChart from "@/features/Deals/DealsChart.vue";
import { provideFilters } from "@/composables/filters/useFilters";
import FiltersView from "@/composables/filters/FiltersView.vue";
import { useUsersStore } from "@/features/Users/useUsersStore";
import { storeToRefs } from "pinia";
import { useTeam } from "@/features/Teams/useTeam";
import { useRouter } from "vue-router";

dayjs.extend(quarterOfYear);
const { timestampCreated, timestampClosed } = provideFilters();
const router = useRouter();
const usersStore = useUsersStore();
const { users } = storeToRefs(usersStore);
const teamsStore = useTeam();
const { teams } = storeToRefs(teamsStore);
const onBarClick = (type = "team", { label }) => {
  console.log(type, label);
  if (type === "team") {
    const team = teams.value.find((t) => t.name === label);
    console.log(team);
    if (team?.team_id) {
      let query = {
        team: team.team_id,
      };
      if (timestampCreated.value) {
        query.timestampCreated = timestampCreated.value.toString();
      }
      if (timestampClosed.value) {
        query.timestampClosed = timestampClosed.value.toString();
      }
      router.push({
        name: "lead-source",
        query,
      });
    }
  }
  if (type === "user") {
    const user = users.value.find(
      (u) => `${u.first_name} ${u.last_name}` === label
    );
    if (user?.user_id) {
      let query = {
        user: user.user_id,
      };
      if (timestampCreated.value) {
        query.timestampCreated = timestampCreated.value.toString();
      }
      if (timestampClosed.value) {
        query.timestampClosed = timestampClosed.value.toString();
      }
      router.push({
        name: "lead-source",
        query,
      });
    }
    console.log(user);
  }
};
</script>

<template>
  <div class="charts">
    <n-card style="margin-bottom: 12px">
      <n-space style="display: flex; justify-content: space-between">
        <h2 style="margin: 0; padding: 0">Unit Sales</h2>
        <FiltersView deal_phase deal_source teams users />
      </n-space>
    </n-card>
    <n-grid :x-gap="12" :y-gap="12" :cols="1">
      <n-grid-item>
        <n-card title="Results by Country">
          <DealsChart
            bar-one-total-label="Total unit target"
            bar-two-total-label="Total unit results"
            endpoint="/deals/targets/teams"
            bar-one="target_units"
            bar-one-title="Unit Target"
            bar-two="achieved_units"
            bar-two-title="Unit Result"
            @on-bar-click="(r) => onBarClick('team', r)"
          />
        </n-card>
      </n-grid-item>
      <n-grid-item>
        <n-card title="Results by Sales Manager">
          <DealsChart
            bar-one-total-label="Total unit target"
            bar-two-total-label="Total unit results"
            endpoint="/deals/targets/users"
            bar-one="target_units"
            bar-one-title="Unit Target"
            bar-two="achieved_units"
            bar-two-title="Unit Result"
            @on-bar-click="(r) => onBarClick('user', r)"
          />
        </n-card>
      </n-grid-item>
    </n-grid>
  </div>
</template>

<style scoped lang="scss">
.charts {
  padding: 20px;
  overflow: hidden;
}
</style>
