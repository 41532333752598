import { ref, watch } from "vue";
import { debounce } from "lodash/function";

export const createUsersTarget = () => {
  const editItem = ref(null);
  const year = ref(Date.now());
  const modalRef = ref(false);
  const api = useApi();
  const { t } = useI18n();
  const message = useMessage();
  const targets = ref([
    {
      month: 1672531200,
      value: 0,
    },
    {
      month: 1675209600,
      value: 0,
    },
    {
      month: 1677628800,
      value: 0,
    },
    {
      month: 1680307200,
      value: 0,
    },
    {
      month: 1682899200,
      value: 0,
    },
    {
      month: 1685577600,
      value: 0,
    },
    {
      month: 1688169600,
      value: 0,
    },
    {
      month: 1690848000,
      value: 0,
    },
    {
      month: 1693526400,
      value: 0,
    },
    {
      month: 1696118400,
      value: 0,
    },
    {
      month: 1698796800,
      value: 0,
    },
    {
      month: 1701388800,
      value: 0,
    },
  ]);

  const openUsersTarget = (row) => {
    editItem.value = row;
    modalRef.value = true;
  };

  const onClose = () => {
    modalRef.value = false;
  };

  watch([editItem, year], async () => {
    console.log("changed");
    if (editItem.value?.user_id) {
      const yearDayjs = dayjs(year.value);
      let params = {
        year: yearDayjs.year(),
      };
      await api
        .get(`/users/${editItem?.value?.user_id}/monthly_targets`, {
          params,
        })
        .then(({ data }) => {
          targets.value = data;
        })
        .catch((e) => {
          message.error(
            t(`common.base.${e?.response?.status}`, "common.base.unkown_error")
          );
        });
      console.log("api call");
    }
  });

  const afterHide = () => {
    editItem.value = null;
    targets.value = [];
  };

  const upsertMonthlyTarget = () => {};

  const updateValues = debounce(() => {
    if (!editItem.value?.user_id) return;

    api
      .post(`/users/${editItem?.value?.user_id}/monthly_targets`, {
        targets: targets.value,
      })
      .catch((e) => {
        message.error(
          t(`common.base.${e?.response?.status}`, "common.base.unkown_error")
        );
      });
  }, 250);

  return {
    updateValues,
    openUsersTarget,
    onClose,
    afterHide,
    modalRef,
    year,
    upsertMonthlyTarget,
    targets,
  };
};

import { inject, provide } from "vue";
import dayjs from "dayjs";
import { useApi } from "@/features/Api/useApi";
import { useI18n } from "vue-i18n";
import { useMessage } from "naive-ui";

const UsersTargetKey = Symbol("UsersTarget");

export function provideUsersTarget(props) {
  const inst = createUsersTarget(props);
  provide(UsersTargetKey, inst);
  return inst;
}

export function useUsersTarget() {
  const instance = inject(UsersTargetKey);

  if (!instance) {
    throw new Error("Run provideUsersTarget before useFeature.");
  }

  return instance;
}
