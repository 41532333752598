import { inject, reactive, watch } from "vue";
import axios from "axios";
import { useAuthStore } from "../../store/useAuthStore.js";
import { useRefreshTokenInterceptor } from "./useRefreshTokenInterceptor.js";

export const ApiKey = Symbol("Api");

export function createApi(baseUrlRef, options) {
  const authStore = useAuthStore();

  const axiosInstance = axios.create({ ...options, baseURL: baseUrlRef.value });
  const instance = reactive({
    ...axiosInstance,
    patch,
    refreshToken,
  });
  const { interceptor, startRefresh } = useRefreshTokenInterceptor(instance);

  watch(baseUrlRef, (v) => {
    instance.defaults.baseURL = v;
  });

  watch(
    () => authStore.authHeadersObject,
    (newAuthHeaders) => {
      instance.defaults.headers = {
        ...options.headers,
        ...newAuthHeaders,
      };
    }
  );

  instance.startRefresh = startRefresh;
  instance.interceptors.request.use((config) => {
    const authorizationHeaders = authStore.authHeadersObject?.Authorization;

    if (authorizationHeaders) {
      config.headers.Authorization = authorizationHeaders;
    }

    return config;
  });
  instance.interceptors.response.use((response) => response, interceptor);

  function patch(url, data, config = {}) {
    config = {
      ...config,
      headers: {
        ...config.headers,
      },
    };
    return axiosInstance.patch(url, data, config);
  }

  async function refreshToken() {
    const config = {
      headers: {
        Accept: "application/ld+json",
        "Content-Type": "application/json",
      },
    };

    const body = {
      refresh_token: authStore.refreshToken,
    };

    let response;
    try {
      response = await axios.post(
        baseUrlRef.value + "/auth/refresh",
        body,
        config
      );
    } catch (refreshTokenException) {
      return Promise.reject(new Error("RefreshToken expired"));
    }

    const { data } = response;
    if (!data) {
      return Promise.reject(response);
    }

    authStore.setToken(data.access_token);
    authStore.setRefreshToken(data.refresh_token);
    authStore.setUser(data.user);
  }

  return {
    ApiKey,
    instance,
  };
}

export function useApi() {
  const instance = inject(ApiKey);
  if (!instance) {
    console.error("Run createApi before useApi", instance);
    throw Error();
  }

  return instance;
}
