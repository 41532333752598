<script setup>
import { useUsersTarget } from "@/features/Users/useUsersTargetView";
import UsersTargetTable from "@/features/Users/UsersTargetTable.vue";

const { onClose, afterHide, modalRef } = useUsersTarget();
const segmented = {
  content: "soft",
  footer: "soft",
};
const bodyStyle = { width: "600px" };
</script>
<template>
  <n-modal
    v-model:show="modalRef"
    class="custom-card"
    :style="bodyStyle"
    title="Change targets"
    :bordered="false"
    size="huge"
    preset="dialog"
    :segmented="segmented"
    content="Are you sure?"
    negative-text="Close"
    @negative-click="onClose"
    @after-hide="afterHide"
  >
    <UsersTargetTable />
  </n-modal>
</template>
