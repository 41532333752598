<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useFilters } from "@/composables/filters/useFilters";
import { useDealPhase } from "@/features/Deals/useDealPhase";
const { selectedDealPases } = useFilters();
const deal_phasesStore = useDealPhase();
const { deal_phases } = storeToRefs(deal_phasesStore);
const deal_phasesOptions = computed(() => [
  { label: "Unknown", value: "unknown" },
  ...deal_phases.value.map((deal_phase) => ({
    label: deal_phase.name,
    value: deal_phase.deal_phase_id,
  })),
]);
</script>
<template>
  <n-select
    v-model:value="selectedDealPases"
    :max-tag-count="1"
    placeholder="Filter by phase"
    style="min-width: 150px; max-width: 450px"
    clearable
    filterable
    multiple
    :options="deal_phasesOptions"
  />
</template>
