<script setup>
import { useMessage } from "naive-ui";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import LogoMain from "@/assets/logo.svg";
import { Lock, User } from "@vicons/fa";
import { useApi } from "@/features/Api/useApi";
import { useI18n } from "vue-i18n";

const formRef = ref();
const message = useMessage();
const loading = ref(false);

const formInline = reactive({
  email: "",
  password: "",
  isCaptcha: true,
});

const rules = {
  email: {
    required: true,
    message: "Enter a valid email",
    trigger: "blur",
    type: "email",
  },
  password: {
    required: true,
    message: "Enter a valid password",
    trigger: "blur",
  },
};

const router = useRouter();
const authStore = useAuthStore();
const api = useApi();
const { t } = useI18n();
function submit() {
  formRef.value.validate(async (errors) => {
    if (errors) {
      message.error("Please enter all fields");
      return;
    }

    loading.value = true;

    const { email, password } = formInline;
    api
      .post("/auth/login", { email: email, password })
      .then((r) => {
        authStore.setToken(r.data.access_token);
        authStore.setRefreshToken(r.data.refresh_token);
        authStore.setUser(r.data.user);
        message.success(t("common.login_success"));
        router.push({ name: "sales-unit-targets" });
      })
      .catch((e) => {
        message.error(
          t(`common.base.${e?.response?.status}`, "common.base.unkown_error")
        );
      })
      .finally(() => {
        loading.value = false;
      });
  });
}
</script>

<template>
  <div class="view-account">
    <div class="container">
      <div class="logo">
        <LogoMain />
      </div>
      <n-form
        ref="formRef"
        label-placement="left"
        size="large"
        :model="formInline"
        :rules="rules"
        class="form"
      >
        <n-form-item path="email">
          <n-input
            v-model:value="formInline.email"
            placeholder="Please enter email"
            @keydown.enter="submit"
          >
            <template #prefix>
              <n-icon size="18" color="#808695"> <User /> </n-icon>
            </template>
          </n-input>
        </n-form-item>
        <n-form-item path="password">
          <n-input
            v-model:value="formInline.password"
            type="password"
            show-password-on="click"
            placeholder="Please enter password"
            @keydown.enter="submit"
          >
            <template #prefix>
              <n-icon size="18" color="#808695">
                <Lock />
              </n-icon>
            </template>
          </n-input>
        </n-form-item>
        <n-form-item>
          <n-button
            type="primary"
            size="large"
            :loading="loading"
            block
            @click="submit"
          >
            Login
          </n-button>
        </n-form-item>
      </n-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.n-form-item) {
  margin-bottom: 20px;
}
.view-account {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-image: url("./login-bg.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  align-items: center;

  .container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 12px;
    max-width: 384px;
    min-width: 320px;
    gap: 25px;

    .form {
      width: 100%;
    }
  }
}
</style>
