<script setup>
import { useUsersAgreement } from "@/features/Users/useUsersAgreement";

const {
  bodyStyle,
  manualAgreementModal,
  segmented,
  onPositiveClick,
  afterHide,
  password,
  loadingUpdate,
} = useUsersAgreement();
</script>
<template>
  <n-modal
    v-model:show="manualAgreementModal"
    class="custom-card"
    :style="bodyStyle"
    title="Update password?"
    :bordered="false"
    size="huge"
    :mask-closable="false"
    preset="dialog"
    :segmented="segmented"
    content="Are you sure?"
    positive-text="Confirm"
    negative-text="Cancel"
    @positive-click="onPositiveClick"
    @after-hide="afterHide"
  >
    <n-input
      v-model:value="password"
      minlength="6"
      :loading="loadingUpdate"
      placeholder="Enter new password"
    />
  </n-modal>
</template>
