<script setup>
import { useFilters } from "@/composables/filters/useFilters";

defineProps({
  createdDateFilter: {
    type: Boolean,
    default: true,
  },
});
const {
  filterTypeCreated,
  onCreateUpdate,
  timestampCreated,
  showAppropriateFilterCreated,
} = useFilters();
const options = [
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Quarterly",
    value: "quarter",
  },
  {
    label: "Yearly",
    value: "year",
  },
];
</script>
<template>
  <n-tooltip trigger="hover">
    <template #trigger>
      <n-space>
        <n-select
          v-if="createdDateFilter"
          v-model:value="filterTypeCreated"
          :on-update:value="showAppropriateFilterCreated"
          :options="options" />
        <n-date-picker
          v-if="createdDateFilter"
          v-model:value="timestampCreated"
          :on-update:value="onCreateUpdate"
          placeholder="Filter by created date"
          :type="filterTypeCreated || 'month'"
          clearable
      /></n-space>
    </template>
    Filter by created date
  </n-tooltip>
</template>
