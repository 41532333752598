import { createPinia } from "pinia";
import { createPersistedStatePlugin } from "pinia-plugin-persistedstate-2";
import { ref } from "vue";
import "./registerServiceWorker";
import { createApi } from "./features/Api/useApi.js";
import router from "./router";
import { createI18n } from "vue-i18n";
import "animate.css";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import * as Sentry from "@sentry/vue";
import { Chart, registerables } from "chart.js";
import { BrowserTracing } from "@sentry/tracing";
import commonEN from "./translations/en/common.json";
import app from "@/configApp";
const pinia = createPinia();
pinia.use(createPersistedStatePlugin());
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
Chart.defaults.set("plugins.datalabels", {
  // clamp: true,
  anchor: "end",
  offset: 20,
  align: "end",
});
Chart.register(...registerables);
Sentry.init({
  app,
  dsn: "https://fc1fb15f8c0a49b3be36056d52a9ae65@sentry.vitrion.nl/26",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "app.linqo.nl", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
const messages = {
  en: {
    common: commonEN,
  },
};
const i18n = createI18n({
  legacy: false,
  locale: "en",
  messages,
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
  },
});

const { ApiKey, instance } = createApi(ref(import.meta.env.VITE_API_URL), {});

app.provide(ApiKey, instance).use(i18n).use(router).mount("#app");
