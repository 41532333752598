import { defineStore } from "pinia";
import { ref } from "vue";
import { useApi } from "@/features/Api/useApi";

export const useDealPhase = defineStore(
  "useDealPhase",
  () => {
    const api = useApi();

    const deal_phases = ref([]);
    const loading = ref(false);
    const loadDealPhases = async () => {
      if (loading.value) {
        return;
      }
      loading.value = true;
      await api
        .get("/deal-phase")
        .then(({ data }) => {
          deal_phases.value = data;
        })
        .finally(() => (loading.value = false));
    };
    return {
      loadDealPhases,
      deal_phases,
    };
  },
  {
    persist: [
      {
        paths: ["deal_phases"],
        storage: sessionStorage,
      },
    ],
  }
);
