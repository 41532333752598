import { useAuthStore } from "@/store/useAuthStore";

const TOKEN_LOGIN_URL = "/auth/login";
const TOKEN_REFRESH_URL = "/auth/refresh";

export function useRefreshTokenInterceptor({ request, refreshToken }) {
  let isRefreshing = false;
  const authStore = useAuthStore();

  const state = {
    failedQueue: [],
  };

  async function startRefresh() {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        state.failedQueue.push({ resolve, reject });
      });
    }

    function refreshSuccess() {
      processQueue();
      isRefreshing = false;
    }

    function refreshFailed(error) {
      processQueue(error);
      isRefreshing = false;
      throw error;
    }

    isRefreshing = true;

    try {
      await refreshToken();
    } catch (error) {
      return refreshFailed(error);
    }
    return refreshSuccess();
  }

  async function interceptor(failedRequest) {
    console.log("interceptor");
    const originalRequest = failedRequest.config;

    if ([TOKEN_REFRESH_URL, TOKEN_LOGIN_URL].includes(originalRequest.url)) {
      throw failedRequest;
    }

    if (failedRequest.response.status !== 401) {
      throw failedRequest;
    }

    if (originalRequest.retry) {
      throw failedRequest;
    }

    originalRequest.retry = true;
    return startRefresh().then(() => {
      originalRequest.headers.Authorization = `Bearer ${authStore.token}`;
      return request(originalRequest);
    });
  }

  function processQueue(error = null) {
    state.failedQueue.forEach((promise) => {
      if (error) promise.reject(error);
      promise.resolve();
    });
  }

  return {
    interceptor,
    startRefresh,
  };
}
