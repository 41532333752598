<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useFilters } from "@/composables/filters/useFilters";
import { useDealSource } from "@/features/Deals/useDealSource";
const { selectedDealSources } = useFilters();
const deal_sourcesStore = useDealSource();
const { deal_sources } = storeToRefs(deal_sourcesStore);
const deal_sourcesOptions = computed(() => [
  { label: "Unknown", value: "unknown" },
  ...deal_sources.value.map((deal_source) => ({
    label: deal_source.name,
    value: deal_source.deal_source_id,
  })),
]);
</script>
<template>
  <n-select
    v-model:value="selectedDealSources"
    :max-tag-count="1"
    placeholder="Filter by source"
    filterable
    style="min-width: 250px; max-width: 450px"
    clearable
    multiple
    :options="deal_sourcesOptions"
  />
</template>
