<template>
  <n-grid cols="2 s:2 m:2 l:3 xl:3 2xl:3" responsive="screen">
    <n-grid-item>
      <n-space vertical>
        <n-text>Sync teamleader with Linqo</n-text>
        <n-popconfirm @positive-click="startSync">
          <template #trigger>
            <n-button type="primary" :loading="syncLoading">Sync</n-button>
          </template>
          Are you sure u wanna start the sync
        </n-popconfirm>
      </n-space>
      <n-divider />
      <n-space vertical>
        <n-text>Set webhooks for teamleader</n-text>
        <n-popconfirm @positive-click="setWebhooks">
          <template #trigger>
            <n-button type="primary" :loading="webhooksLoading"
              >Set webhooks</n-button
            >
          </template>
          Are you sure u wanna set the webhooks again
        </n-popconfirm>
      </n-space>
      <n-divider />
      <n-space vertical style="margin: 5px 0">
        <n-text>Connect Teamleader to Linqo</n-text>
        <n-button type="info" @click="openCallback">Connect</n-button>
      </n-space>
    </n-grid-item>
  </n-grid>
</template>

<script lang="ts" setup>
// import { useMessage } from "naive-ui";
//
// const message = useMessage();
import { ref } from "vue";
import { useMessage } from "naive-ui";
import { useApi } from "@/features/Api/useApi";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const api = useApi();
const message = useMessage();

const syncLoading = ref(false);
const webhooksLoading = ref(false);

const openCallback = () => {
  window.open(
    `https://focus.teamleader.eu/oauth2/authorize?response_type=code&redirect_uri=${
      import.meta.env.VITE_TEAMLEADER_CALLBACK_URL
    }&client_id=b57f69f92d48a1ceaf8365ea2196b9ad`,
    "_blank"
  );
};
const startSync = () => {
  if (syncLoading.value) {
    return;
  }
  syncLoading.value = true;
  api
    .get("/teamleader/sync")
    .then(() => {
      message.success(t("common.sync_success"));
    })
    .catch(() => {
      message.warning(
        "Sync took longer then 30 seconds, because of this we can't track it anymore"
      );
    })
    .finally(() => {
      syncLoading.value = false;
    });
};
const setWebhooks = () => {
  if (webhooksLoading.value) {
    return;
  }
  webhooksLoading.value = true;
  api
    .get("/teamleader/webhook")
    .then(() => {
      message.success(t("common.sync_success"));
    })
    .catch((e) => {
      message.error(
        t(`common.base.${e?.response?.status}`, "common.base.unkown_error")
      );
    })
    .finally(() => {
      webhooksLoading.value = false;
    });
};
</script>
