import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";

export const useAuthStore = defineStore(
  "useAuthStore",
  () => {
    const token = ref(null);
    const refreshToken = ref(null);
    const loading = ref(true);
    const user = reactive({});

    const isLoggedIn = computed(() => {
      return token.value !== null;
    });

    const isRemembered = computed(() => {
      return refreshToken.value !== null;
    });

    const authHeadersObject = computed(() => {
      if (!isLoggedIn.value) {
        return null;
      }

      return {
        Authorization: `Bearer ${token.value}`,
      };
    });

    function setToken(v) {
      token.value = v;
    }

    function setRefreshToken(v) {
      refreshToken.value = v;
    }

    function setLoading(v) {
      loading.value = v;
    }

    function setUser(input) {
      Object.assign(user, input);
    }

    function reset() {
      token.value = null;
      refreshToken.value = null;
      for (let k in user) {
        delete user[k];
      }
    }

    return {
      reset,
      setUser,
      user,
      authHeadersObject,
      loading,
      setToken,
      setLoading,
      setRefreshToken,
      token,
      isRemembered,
      refreshToken,
      isLoggedIn,
    };
  },
  {
    persist: [
      {
        paths: ["token", "user"],
        storage: sessionStorage,
      },
      {
        paths: ["refreshToken"],
        storage: localStorage,
      },
    ],
  }
);
