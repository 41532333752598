<script setup>
import { useApi } from "./features/Api/useApi.js";
import { useAuthStore } from "./store/useAuthStore.js";
import { RouterLink, useRoute, useRouter } from "vue-router";
import { darkTheme, NIcon } from "naive-ui";
import Logo from "@/assets/logo.svg";
import LogoSquare from "@/assets/logo_square.png";

import { computed, h, watch } from "vue";
import {
  TachometerAlt,
  HandHoldingUsd,
  SearchLocation,
  Handshake,
  UserCog,
  Cog,
  Users,
} from "@vicons/fa";
import { useI18n } from "vue-i18n";
import { useUsersStore } from "@/features/Users/useUsersStore";
import { useDealSource } from "@/features/Deals/useDealSource";
import { useDealPhase } from "@/features/Deals/useDealPhase";
import { useTeam } from "@/features/Teams/useTeam";

const themeOverrides = {
  common: {
    primaryColor: "#ff9c09FF",
    primaryColorHover: "rgba(220, 143, 30, 1)",
    primaryColorPressed: "#BE7F22FF",
    primaryColorSuppl: "#ff9c09FF",
  },
  Select: {
    peers: {
      InternalSelection: {
        textColor: "#ff9c09",
      },
    },
  },
};

const api = useApi();
const authStore = useAuthStore();
const usersStore = useUsersStore();
const dealSourceStore = useDealSource();
const dealPhaseStore = useDealPhase();
const teamsStore = useTeam();

const router = useRouter();
const route = useRoute();
const isPublicRoute = computed(() => route.meta.public === true);

async function startUp() {
  if (!authStore.isLoggedIn && authStore.isRemembered) {
    await api
      .refreshToken()
      .then(() => {
        if (isPublicRoute.value) return;
        router.push({ name: "sales-unit-targets" });
      })
      .catch(() => {
        authStore.reset();
        router.push({ name: "login" });
      });
  }

  authStore.setLoading(false);
}

watch(
  authStore.isLoggedin,
  async () => {
    if (authStore.isLoggedIn) {
      usersStore.loadUsers().then();
      dealSourceStore.loadDealSources().then();
      dealPhaseStore.loadDealPhases().then();
      teamsStore.loadTeams().then();
    }
  },
  { immediate: true }
);

startUp();
const isNotUser = computed(() => {
  return authStore.user.role !== "user";
});
const isAdmin = computed(() => {
  return authStore.user.role === "admin";
});
const menuOptions = computed(() => [
  {
    label: renderRouterLink({ name: "sales-unit-targets" }, "Unit Sales"),
    key: "sales-unit-targets",
    icon: renderIcon(TachometerAlt),
  },
  ...(isNotUser.value
    ? [
        {
          label: renderRouterLink({ name: "unit-bills" }, "Units Processed"),
          key: "unit-bills",
          icon: renderIcon(HandHoldingUsd),
        },
      ]
    : []),
  {
    label: renderRouterLink({ name: "lead-source" }, "Lead Source"),
    key: "lead-source",
    icon: renderIcon(SearchLocation),
  },
  {
    label: renderRouterLink({ name: "deals" }, "Commissions"),
    key: "deals",
    icon: renderIcon(Handshake),
  },
  ...(isNotUser.value
    ? [
        {
          label: renderRouterLink({ name: "companies" }, "Customers"),
          key: "companies",
          icon: renderIcon(Users),
        },
        {
          label: renderRouterLink({ name: "users" }, "Users"),
          key: "users",
          icon: renderIcon(UserCog),
        },
      ]
    : []),
  ...(isAdmin.value
    ? [
        {
          label: renderRouterLink({ name: "settings" }, "Settings"),
          key: "settings",
          icon: renderIcon(Cog),
        },
      ]
    : []),
]);
const { t } = useI18n();

function renderRouterLink(to, label) {
  return () =>
    h(
      RouterLink,
      {
        to,
      },
      { default: () => label }
    );
}

const dropdownOptions = [
  {
    label: t("common.logout"),
    key: "logout",
  },
];

function onDropdownSelect(key) {
  switch (key) {
    case "logout":
      router.push({ name: "login" });
      authStore.reset();
  }
}

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}
</script>

<template>
  <n-config-provider
    class="full-height"
    :dark-theme="darkTheme"
    :theme-overrides="themeOverrides"
  >
    <n-message-provider>
      <div v-if="authStore.loading" class="center">
        <n-spin description="Bezig met opstarten..."></n-spin>
      </div>
      <template v-else>
        <n-layout>
          <n-layout-header
            v-if="authStore.isLoggedIn && !isPublicRoute"
            style="height: 65px"
            bordered
          >
            <div class="header">
              <Logo />
              <n-dropdown
                trigger="hover"
                :options="dropdownOptions"
                @select="onDropdownSelect"
              >
                <n-avatar round size="large" :src="LogoSquare" />
              </n-dropdown>
            </div>
          </n-layout-header>
          <n-layout
            :class="{ 'main-layout': authStore.isLoggedIn && !isPublicRoute }"
            has-sider
          >
            <n-layout-sider
              v-if="authStore.isLoggedIn && !isPublicRoute"
              bordered
              show-trigger
              collapse-mode="width"
              :collapsed-width="64"
              :width="240"
              :native-scrollbar="false"
            >
              <n-menu
                :value="route.name"
                :inverted="true"
                :collapsed-width="64"
                :collapsed-icon-size="22"
                :options="menuOptions"
              />
            </n-layout-sider>
            <n-layout class="layout-default-background">
              <router-view />
            </n-layout>
          </n-layout>
        </n-layout>
      </template>
    </n-message-provider>
  </n-config-provider>
</template>

<style lang="scss">
.main-layout {
  height: calc(100vh - 65px) !important;
}

html,
body {
  height: 100%;
  margin: 0;
}

.n-config-provider,
.full-height {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
}

#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-default-background {
  background: #f5f7f9 !important;
}

.n-layout {
  height: 100%;
  //height: calc(100vh - 50px);
}

.n-layout-sider {
  background-color: #2a2a2a;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
}
</style>
