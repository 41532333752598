<script setup>
import { computed } from "vue";
import { useUsersStore } from "@/features/Users/useUsersStore";
import { storeToRefs } from "pinia";
import { useFilters } from "@/composables/filters/useFilters";
const { selectedUsers } = useFilters();
const usersStore = useUsersStore();
const { users } = storeToRefs(usersStore);
const usersOptions = computed(() =>
  users?.value.map((user) => ({
    label: `${user.first_name} asd ${user.last_name}`,
    value: user.user_id,
  }))
);
</script>
<template>
  <n-select
    v-model:value="selectedUsers"
    :max-tag-count="2"
    placeholder="Filter by user"
    filterable
    style="min-width: 200px; max-width: 450px"
    clearable
    multiple
    :options="usersOptions"
  />
</template>
