<script setup>
import { computed } from "vue";
import { useTeam } from "@/features/Teams/useTeam";
import { storeToRefs } from "pinia";
import { useFilters } from "@/composables/filters/useFilters";
const { selectedTeams } = useFilters();
const teamsStore = useTeam();
const { teams } = storeToRefs(teamsStore);
const teamsOptions = computed(() =>
  teams?.value.map((team) => ({
    label: team.name,
    value: team.team_id,
  }))
);
</script>
<template>
  <n-select
    v-model:value="selectedTeams"
    :max-tag-count="2"
    placeholder="Filter by team"
    filterable
    style="min-width: 200px; max-width: 450px"
    clearable
    multiple
    :options="teamsOptions"
  />
</template>
