<script setup>
import { useFilters } from "@/composables/filters/useFilters";
import UsersFilter from "@/composables/filters/UsersFilter.vue";
import DealPhasesFilter from "@/composables/filters/DealPhasesFilter.vue";
import DealSourcesFilter from "@/composables/filters/DealSourcesFilter.vue";
import TeamsFilter from "@/composables/filters/TeamsFilter.vue";
import { computed } from "vue";
import { useAuthStore } from "@/store/useAuthStore";
import CreateDateFilter from "@/composables/filters/CreateDateFilter.vue";
import ClosedDateFilter from "@/composables/filters/ClosedDateFilter.vue";
const authStore = useAuthStore();

const isNotUser = computed(() => {
  return authStore.user.role !== "user";
});
const props = defineProps({
  admin_based: {
    type: Boolean,
    default: false,
  },
  search: {
    type: Boolean,
    default: false,
  },
  closedDateFilter: {
    type: Boolean,
    default: true,
  },
  createdDateFilter: {
    type: Boolean,
    default: true,
  },
  searchPlaceHolder: {
    type: String,
    default: "Search by company name",
  },
  users: {
    type: Boolean,
    default: false,
  },
  teams: {
    type: Boolean,
    default: false,
  },
  deal_source: {
    type: Boolean,
    default: false,
  },
  deal_phase: {
    type: Boolean,
    default: false,
  },
});

const { search: internalSearch } = useFilters();

const adminShow = computed(() => {
  if (props.admin_based) {
    return isNotUser.value;
  }
  return true;
});
</script>
<template>
  <n-space vertical>
    <n-space justify="space-between">
      <n-input
        v-if="search"
        v-model:value="internalSearch"
        clearable
        style="width: 300px"
        type="text"
        :placeholder="searchPlaceHolder"
      />
      <n-space>
        <UsersFilter v-if="adminShow && users" />
        <TeamsFilter v-if="adminShow && teams" />
        <DealPhasesFilter v-if="deal_phase" />
        <DealSourcesFilter v-if="deal_source" />
      </n-space>
    </n-space>
    <n-space justify="end">
      <ClosedDateFilter :closed-date-filter="closedDateFilter" />
      <CreateDateFilter :created-date-filter="createdDateFilter" />
    </n-space>
  </n-space>
</template>
