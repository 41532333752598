import { computed, h, onMounted, reactive, ref, watch } from "vue";
import { NInputNumber, NTooltip, useMessage } from "naive-ui";
import dayjs from "dayjs";
import { provideFilters } from "@/composables/filters/useFilters";
import { useApi } from "@/features/Api/useApi";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { useAuthStore } from "@/store/useAuthStore";
import { useI18n } from "vue-i18n";

dayjs.extend(quarterOfYear);

export const useDealsTable = () => {
  const message = useMessage();
  const dataRef = ref([]);
  const api = useApi();
  const { t } = useI18n();
  const loadingRef = ref(false);

  const authStore = useAuthStore();

  const isNotUser = computed(() => {
    return authStore.user.role !== "user";
  });
  const {
    timestampCreated,
    filterTypeCreated,
    timestampClosed,
    filterTypeClosed,
    searchValue,
    selectedUsers,
    selectedTeams,
    selectedDealPases,
    selectedDealSources,
  } = provideFilters();
  // /Users/diongrendelman/PhpstormProjects/linqo/web/src/features/Deals/useDealsTable.js
  const columns = [
    {
      title: "User",
      key: ["responsible_user", "first_name"],
      width: 100,
      render: (row) => {
        return h(
          NTooltip,
          {
            trigger: "hover",
          },
          {
            trigger: () =>
              row.responsible_user?.first_name
                ? `${row.responsible_user.first_name} ${row.responsible_user.last_name}`
                : "",
            default: () => row?.deal_id,
          }
        );
      },
    },
    {
      title: "Company",
      key: ["lead", "name"],
      width: 100,
      render: (row) => {
        const name = row.lead?.name ?? "";
        if (name.length <= 15) {
          return name;
        } else {
          return name.slice(0, 15) + "...";
        }
      },
    },
    {
      title: "Phase",
      key: ["current_phase", "name"],
      width: 100,
    },
    {
      title: "Source",
      key: ["source", "name"],
      width: 100,
    },
    {
      title: "Created at",
      key: "third_party_created_at",
      width: 100,
      render: (row) => {
        return row?.third_party_created_at
          ? dayjs.unix(row?.third_party_created_at).format("DD-MM-YYYY HH:mm")
          : "";
      },
      sorter: true,
    },
    {
      title: "Closed at",
      key: "closed_at",
      width: 100,
      render: (row) => {
        return row?.closed_at
          ? dayjs.unix(row?.closed_at).format("DD-MM-YYYY HH:mm")
          : "";
      },
      sorter: true,
    },
    {
      title: "Total Units",
      key: "total_units",
      width: 100,
      render: (row) => {
        if (row.is_total_row) {
          return h(
            "div",
            { class: "total-row", style: { fontWeight: "bold" } },
            `Total: ${row.total_units}`
          );
        }
        return row?.total_units;
      },
      sorter: true,
    },

    {
      title: "Units Processed",
      key: "paid_units",
      width: 100,
      render: (row, index) => {
        if (row.is_total_row) {
          return h(
            "div",
            { class: "total-row", style: { fontWeight: "bold" } },
            `Total: ${row.paid_units}`
          );
        }
        return h(NInputNumber, {
          type: "number",
          name: "paid_units",
          min: 0,
          disabled: !isNotUser.value,
          value: parseInt(row.paid_units),
          onUpdateValue: (value) => {
            let oldValue = dataRef.value[index].paid_units;
            dataRef.value[index].paid_units = value;
            console.log("calling debounce");
            api
              .patch(`/deals/${row.deal_id}`, {
                paid_units: parseInt(value),
              })
              .catch((e) => {
                console.log(e);
                message.error(
                  t(
                    `common.base.${e?.response?.status}`,
                    "common.base.unkown_error"
                  )
                );
                dataRef.value[index].paid_units = oldValue;
              });
          },
        });
      },
      sorter: true,
    },
    {
      title: "Commission Rate",
      key: "commission_rate",
      width: 100,
      render: (row, index) => {
        if (row.is_total_row) {
          return h(
            "div",
            { class: "total-row", style: { fontWeight: "bold" } },
            `Total: €${row.commission_rate.toFixed(2)}`
          );
        }
        return h(
          NInputNumber,
          {
            disabled: !isNotUser.value,
            type: "number",
            name: "commission_rate",
            min: 0,
            step: 0.1,
            value: parseFloat(row.commission_rate),
            onUpdateValue: (value) => {
              let newValue = value.toFixed(2);
              let oldValue = dataRef.value[index].commission_rate;
              dataRef.value[index].commission_rate = newValue;
              console.log("calling debounce");
              api
                .patch(`/deals/${row.deal_id}`, {
                  commission_rate: parseFloat(newValue),
                })
                .catch((e) => {
                  console.log(e);
                  message.error(
                    t(
                      `common.base.${e?.response?.status}`,
                      "common.base.unkown_error"
                    )
                  );
                  dataRef.value[index].commission_rate = oldValue;
                });
            },
          },
          {
            prefix: () => "€",
          }
        );
      },
      sorter: true,
    },
    {
      title: "Total Commission",
      key: "total_commission",
      width: 100,
      render: (row) => {
        if (row.is_total_row) {
          return h(
            "div",
            { class: "total-row", style: { fontWeight: "bold" } },
            `Total: €${row.total_commission.toFixed(2)}`
          );
        }
        const totalCommission =
          (row.commission_rate ? parseFloat(row.commission_rate) : 0) *
          (row.paid_units ? parseInt(row.paid_units) : 0);
        return `€${totalCommission.toFixed(2)}`;
      },
      sorter: (a, b, direction) => {
        const aCommission =
          (a.commission_rate ? parseFloat(a.commission_rate) : 0) *
          (a.paid_units ? parseInt(a.paid_units) : 0);
        const bCommission =
          (b.commission_rate ? parseFloat(b.commission_rate) : 0) *
          (b.paid_units ? parseInt(b.paid_units) : 0);
        return direction === "ascend"
          ? aCommission - bCommission
          : bCommission - aCommission;
      },
    },
    {
      title: "Commission Paid",
      key: "commission_paid",
      width: 100,
      render: (row, index) => {
        if (row.is_total_row) {
          return h(
            "div",
            { class: "total-row", style: { fontWeight: "bold" } },
            `Total: €${row.commission_paid.toFixed(2)}`
          );
        }
        return h(
          NInputNumber,
          {
            disabled: !isNotUser.value,
            type: "number",
            name: "commission_paid",
            min: 0,
            step: 0.1,
            value: parseFloat(row.commission_paid),
            onUpdateValue: (value) => {
              let newValue = value.toFixed(2);
              let oldValue = dataRef.value[index].commission_paid;
              dataRef.value[index].commission_paid = newValue;
              console.log("calling debounce");
              api
                .patch(`/deals/${row.deal_id}`, {
                  commission_paid: parseFloat(newValue),
                })
                .catch((e) => {
                  console.log(e);
                  message.error(
                    t(
                      `common.base.${e?.response?.status}`,
                      "common.base.unkown_error"
                    )
                  );
                  dataRef.value[index].commission_paid = oldValue;
                });
            },
          },
          {
            prefix: () => "€",
          }
        );
      },
      sorter: true,
    },
    {
      title: "Comission Unpaid",
      key: "commission_unpaid",
      width: 100,
      render: (row) => {
        if (row.is_total_row) {
          return h(
            "div",
            { class: "total-row", style: { fontWeight: "bold" } },
            `Total: €${row.commission_unpaid.toFixed(2)}`
          );
        }
        const totalCommission =
          (row.commission_rate ? parseFloat(row.commission_rate) : 0) *
          (row.paid_units ? parseInt(row.paid_units) : 0);
        const commissionPaid = totalCommission - row.commission_paid;
        return `€${commissionPaid.toFixed(2)}`;
      },
      sorter: (a, b, direction) => {
        const aTotalCommission =
          (a.commission_rate ? parseFloat(a.commission_rate) : 0) *
          (a.paid_units ? parseInt(a.paid_units) : 0);
        const aCommissionPaid = aTotalCommission - a.commission_paid;
        const bTotalCommission =
          (b.commission_rate ? parseFloat(b.commission_rate) : 0) *
          (b.paid_units ? parseInt(b.paid_units) : 0);
        const bCommissionPaid = bTotalCommission - b.commission_paid;
        return direction === "ascend"
          ? aCommissionPaid - bCommissionPaid
          : bCommissionPaid - aCommissionPaid;
      },
    },
  ];

  const pageSize = 100;

  const query = async (
    page,
    pageSize = pageSize,
    search = "",
    time_created,
    type_created,
    time_closed,
    type_closed,
    users,
    teams,
    deal_phases,
    deal_sources,
    sort
  ) => {
    let params = {
      page: page,
      pageSize: pageSize,
    };
    if (search) {
      params.search = search;
    }
    if (time_created && type_created) {
      const dateTime = dayjs(time_created);
      params["created_at_after"] = dateTime.startOf(type_created).unix();
      params["created_at_before"] = dateTime.endOf(type_created).unix();
    }
    if (sort?.order && typeof sort?.sorter === "boolean") {
      params[`sort[${sort.columnKey}]`] =
        sort.order === "ascend" ? "ASC" : "DESC";
    }

    if (time_closed && type_closed) {
      const dateTime = dayjs(time_closed);
      params["closed_at_after"] = dateTime.startOf(type_closed).unix();
      params["closed_at_before"] = dateTime.endOf(type_closed).unix();
    }
    if (users?.length > 0) {
      params.users = users.join(",");
    }
    if (teams?.length > 0) {
      params.teams = teams.join(",");
    }
    if (deal_phases?.length > 0) {
      params.deal_phases = deal_phases.join(",");
    }
    if (deal_sources?.length > 0) {
      params.deal_sources = deal_sources.join(",");
    }
    if (sort?.order && typeof sort?.sorter === "function") {
      let result = await api.get("/deals", {
        params,
      });
      result.data.data.sort((a, b) => sort.sorter(a, b, sort.order));
      return result;
    } else {
      return await api.get("/deals", {
        params,
      });
    }
  };

  const paginationReactive = reactive({
    page: 1,
    pageCount: 1,
    pageSize: pageSize,
    showQuickJumper: true,
    showSizePicker: true,
    pageSizes: [50, 100, 200, 500, 1000],
    prefix: ({ itemCount }) => {
      return `Total is ${itemCount}.`;
    },
  });

  const fetchDeals = async ({ pageSize, page, sort }) => {
    if (loadingRef.value) return;
    loadingRef.value = true;
    console.log(page !== undefined ? page : paginationReactive.page);
    await query(
      page !== undefined ? page : paginationReactive.page,
      pageSize !== undefined ? pageSize : paginationReactive.pageSize,
      searchValue.value,
      timestampCreated.value,
      filterTypeCreated.value,
      timestampClosed.value,
      filterTypeClosed.value,
      selectedUsers.value,
      selectedTeams.value,
      selectedDealPases.value,
      selectedDealSources.value,
      sort
    ).then(({ data }) => {
      dataRef.value = data.data;
      paginationReactive.page = parseInt(data.meta.page);
      paginationReactive.pageSize = parseInt(data.meta.pageSize);
      paginationReactive.pageCount = data.meta.pageCount;
      paginationReactive.itemCount = data.meta.itemCount;
      loadingRef.value = false;
    });
  };

  watch(
    [
      searchValue,
      timestampCreated,
      filterTypeCreated,
      timestampClosed,
      filterTypeClosed,
      selectedUsers,
      selectedTeams,
      selectedDealPases,
      selectedDealSources,
    ],
    () => {
      paginationReactive.page = 1;
      fetchDeals({});
    }
  );
  onMounted(() => {
    fetchDeals({});
  });

  const handlePageChange = (currentPage) => {
    console.log(currentPage);
    fetchDeals({ page: currentPage });
  };
  const handleSortChange = (sort) => {
    console.log(sort);
    fetchDeals({ sort: sort });
  };

  const handlePageSizeChange = (pageSize) => {
    fetchDeals({ pageSize });
  };

  const totalUnits = computed(() => {
    return dataRef.value
      ? dataRef.value.reduce((acc, row) => {
          return acc + (row.total_units ? parseInt(row.total_units) : 0);
        }, 0)
      : 0;
  });
  const totalPaidUnits = computed(() => {
    return dataRef.value
      ? dataRef.value.reduce((acc, row) => {
          return acc + (row.paid_units ? parseInt(row.paid_units) : 0);
        }, 0)
      : 0;
  });
  const totalCommissionRate = computed(() => {
    return dataRef.value
      ? dataRef.value.reduce((acc, row) => {
          return (
            acc + (row.commission_rate ? parseFloat(row.commission_rate) : 0)
          );
        }, 0)
      : 0;
  });
  const totalCommission = computed(() => {
    return dataRef.value
      ? dataRef.value.reduce((acc, row) => {
          const totalCommission =
            (row.commission_rate ? parseFloat(row.commission_rate) : 0) *
            (row.paid_units ? parseInt(row.paid_units) : 0);
          return acc + totalCommission;
        }, 0)
      : 0;
  });

  const totalUnpaid = computed(() => {
    return dataRef.value
      ? dataRef.value.reduce((acc, row) => {
          const totalCommission =
            (row.commission_rate ? parseFloat(row.commission_rate) : 0) *
            (row.paid_units ? parseInt(row.paid_units) : 0);
          const commissionPaid = totalCommission - row.commission_paid;
          return acc + commissionPaid;
        }, 0)
      : 0;
  });
  const totalCommissionPaid = computed(() => {
    return dataRef.value
      ? dataRef.value.reduce((acc, row) => {
          return (
            acc + (row.commission_paid ? parseFloat(row.commission_paid) : 0)
          );
        }, 0)
      : 0;
  });
  return {
    totalCommission,
    totalUnpaid,
    totalCommissionPaid,
    totalPaidUnits,
    totalUnits,
    totalCommissionRate,
    handleSortChange,
    columns,
    dataRef,
    handlePageChange,
    handlePageSizeChange,
    paginationReactive,
    loadingRef,
  };
};
