<script setup>
import { useFilters } from "@/composables/filters/useFilters";

defineProps({
  closedDateFilter: {
    type: Boolean,
    default: true,
  },
});
const {
  filterTypeClosed,
  onClosedUpdate,
  timestampClosed,
  showAppropriateFilterClosed,
} = useFilters();
const options = [
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Quarterly",
    value: "quarter",
  },
  {
    label: "Yearly",
    value: "year",
  },
];
</script>
<template>
  <n-tooltip trigger="hover">
    <template #trigger>
      <n-space>
        <n-select
          v-if="closedDateFilter"
          v-model:value="filterTypeClosed"
          :on-update:value="showAppropriateFilterClosed"
          :options="options" />
        <n-date-picker
          v-if="closedDateFilter"
          v-model:value="timestampClosed"
          :on-update:value="onClosedUpdate"
          placeholder="Filter by closed date"
          :type="filterTypeClosed || 'month'"
          clearable
      /></n-space>
    </template>
    Filter by closed date
  </n-tooltip>
</template>
