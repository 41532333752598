<script setup>
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import DealsChart from "@/features/Deals/DealsChart.vue";
import { provideFilters } from "@/composables/filters/useFilters";
import FiltersView from "@/composables/filters/FiltersView.vue";
dayjs.extend(quarterOfYear);
provideFilters();
</script>

<template>
  <div class="charts">
    <n-card style="margin-bottom: 12px">
      <div style="display: flex; justify-content: space-between">
        <h2 style="margin: 0; padding: 0">Units Processed</h2>
        <FiltersView deal_phase deal_source teams users />
      </div>
    </n-card>
    <n-grid :x-gap="12" :y-gap="12" :cols="1">
      <n-grid-item>
        <n-card title="Results by Country">
          <DealsChart
            bar-one-total-label="Total unit results"
            bar-two-total-label="Total processed results"
            endpoint="/deals/billed/teams"
            bar-one="achieved_units"
            bar-one-title="Unit Result"
            bar-two="paid_units"
            bar-two-title="Processed units"
          />
        </n-card>
      </n-grid-item>
      <n-grid-item>
        <n-card title="Results by Sales Manager">
          <DealsChart
            bar-one-total-label="Total unit results"
            bar-two-total-label="Total processed results"
            endpoint="/deals/billed/users"
            bar-one="achieved_units"
            bar-one-title="Unit Result"
            bar-two="paid_units"
            bar-two-title="Processed units"
          />
        </n-card>
      </n-grid-item>
    </n-grid>
  </div>
</template>

<style scoped lang="scss">
.charts {
  padding: 20px;
  overflow: hidden;
}
</style>
