import { createRouter, createWebHistory } from "vue-router";
import CompaniesView from "../features/Companies/CompaniesView.vue";
import DealsView from "../features/Deals/DealsView.vue";
import LeadSourceView from "../features/LeadSource/LeadSourceView.vue";
import LoginView from "../features/Login/LoginView.vue";
import SalesUnitTargetView from "../features/SalesUnitTargets/SalesUnitTargetView.vue";
import SettingsView from "../features/SettingsView/SettingsView.vue";
import UnitBillsView from "../features/UnitBills/UnitBillsView.vue";
import UsersView from "../features/Users/UsersView.vue";
import { useAuthStore } from "../store/useAuthStore.js";
import AgreementVerifiy from "@/features/agreement/AgreementVerifiy.vue";

const routes = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "login" },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/agreement-verify",
    name: "agreement-verify",
    component: AgreementVerifiy,
    meta: {
      public: true,
    },
  },
  {
    path: "/sales-unit-targets",
    name: "sales-unit-targets",
    component: SalesUnitTargetView,
  },
  {
    path: "/unit-bills",
    name: "unit-bills",
    component: UnitBillsView,
  },
  {
    path: "/lead-source",
    name: "lead-source",
    component: LeadSourceView,
  },
  {
    path: "/deals",
    name: "deals",
    component: DealsView,
  },
  {
    path: "/companies",
    name: "companies",
    component: CompaniesView,
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  console.log(to);
  if (to?.meta.public) {
    return next();
  }

  if (authStore.isLoggedIn && to?.meta?.anonymous) {
    return next({ name: "sales-unit-targets" });
  }

  if (!authStore.isLoggedIn && !to?.meta?.anonymous) {
    return next({ name: "login" });
  }

  next();
});

export default router;
