import { ref } from "vue";
import { inject, provide } from "vue";
import { useApi } from "@/features/Api/useApi";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";

export const createUsersAgreement = () => {
  const message = useMessage();
  const api = useApi();
  const manualAgreementModal = ref(false);
  const editItem = ref(null);
  const password = ref("");
  const { t } = useI18n();
  const afterHide = () => {
    editItem.value = null;
    password.value = "";
  };

  const loadingUpdate = ref(false);
  const segmented = {
    content: "soft",
    footer: "soft",
  };
  const bodyStyle = { width: "600px" };
  const onPositiveClick = () => {
    if (loadingUpdate.value) {
      return;
    }
    if (password.value.length < 7) {
      message.error("Password to short");
      return;
    }
    loadingUpdate.value = true;
    console.log("positive");
    api
      .patch(`/users/${editItem.value.user_id}`, {
        password: password.value,
      })
      .then(() => {
        editItem.value = null;
        password.value = "";
        manualAgreementModal.value = false;
        message.success("Updated");
      })
      .catch((e) => {
        console.log(e);
        message.error(
          t(`common.base.${e?.response?.status}`, "common.base.unkown_error")
        );
      })
      .finally(() => {
        loadingUpdate.value = false;
      });
  };
  const openAgreementModal = (row) => {
    editItem.value = row;
    password.value = "";
    manualAgreementModal.value = true;
  };
  return {
    segmented,
    manualAgreementModal,
    password,
    bodyStyle,
    openAgreementModal,
    onPositiveClick,
    afterHide,
    loadingUpdate,
  };
};

const UsersAgreementKey = Symbol("UsersAgreement");

export function provideUsersAgreement(props) {
  const inst = createUsersAgreement(props);
  provide(UsersAgreementKey, inst);
  return inst;
}

export function useUsersAgreement() {
  const instance = inject(UsersAgreementKey);

  if (!instance) {
    throw new Error("Run provideUsersAgreement before useFeature.");
  }

  return instance;
}
