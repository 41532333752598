<script setup>
import FiltersView from "@/composables/filters/FiltersView.vue";
import { useDealsTable } from "@/features/Deals/useDealsTable";

const rowKey = (rowData) => {
  return rowData.user_id;
};
const {
  totalPaidUnits,
  totalUnits,
  columns,
  dataRef,
  totalCommissionRate,
  totalCommissionPaid,
  totalUnpaid,
  totalCommission,
  handlePageChange,
  handleSortChange,
  handlePageSizeChange,
  paginationReactive,
  loadingRef,
} = useDealsTable();
</script>

<template>
  <div class="users">
    <n-card>
      <h1 style="margin-top: 0">Commissions</h1>
      <n-space vertical>
        <FiltersView admin_based deal_phase deal_source search users teams />
        <n-data-table
          ref="table"
          remote
          :columns="columns"
          :data="[
            ...dataRef,
            {
              total_units: totalUnits,
              paid_units: totalPaidUnits,
              commission_rate: totalCommissionRate,
              total_commission: totalCommission,
              commission_paid: totalCommissionPaid,
              commission_unpaid: totalUnpaid,
              responsible_user: {},
              lead: {},
              current_phase: {},
              source: {},
              is_total_row: true,
              id: 'total',
            },
          ]"
          :loading="loadingRef"
          :pagination="paginationReactive"
          :row-key="rowKey"
          @update:sorter="handleSortChange"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
        />
      </n-space>
    </n-card>
  </div>
</template>

<style scoped lang="scss">
.users {
  padding: 20px;
}
</style>
