<template>
  <n-space class="totals">
    <div
      v-if="
        barOneTotalLabel && barOneTotal !== null && barOneTotal !== undefined
      "
      style="opacity: 0.5"
    >
      {{ barOneTotalLabel }}: {{ barOneTotal }}
    </div>
    <div
      v-if="
        barTwoTotalLabel && barTwoTotal !== null && barTwoTotal !== undefined
      "
      style="opacity: 0.5"
    >
      {{ barTwoTotalLabel }}: {{ barTwoTotal }}
    </div>
  </n-space>
  <div class="chart-wrapper">
    <BarChart
      :style="{ height: `${height}px` }"
      v-bind="barChartProps"
      :chart-data="chartData"
      @chart:render="handleChartRender"
    />
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { BarChart, useBarChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useMessage } from "naive-ui";

const emit = defineEmits(["onBarClick"]);
const props = defineProps({
  chartData: {
    type: Object,
    default: () => {
      return {};
    },
  },
  type: {
    type: String,
    default: () => {
      return "";
    },
  },
  height: {
    type: Number,
    default: 400,
  },
  emptyMessage: {
    type: String,
    default: () => {
      return "Geen data om weer te geven";
    },
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  barOneTotal: {
    type: Number,
    default: null,
  },
  barTwoTotal: {
    type: Number,
    default: null,
  },
  barOneTotalLabel: {
    type: String,
    default: "Total",
  },
  barTwoTotalLabel: {
    type: String,
    default: "Total",
  },
});
const message = useMessage();

const barChartRef = ref();
const onBarHover = (event, chartElement) => {
  const target = event.native ? event.native.target : event.target;
  target.style.cursor = chartElement[0] ? "pointer" : "default";
};

function handleChartRender(chart) {
  barChartRef.value = chart;
}

const onBarClick = (evt) => {
  const barInstance = barChartRef?.value?.getElementsAtEventForMode
    ? barChartRef?.value
    : barChartRef?.value?.chartInstance;
  if (!barInstance?.getElementsAtEventForMode) {
    message.error("Something went wrong");
    return;
  }
  try {
    const points = barInstance.getElementsAtEventForMode(
      evt,
      "nearest",
      { intersect: true },
      true
    );

    if (points.length) {
      const firstPoint = points[0];
      const label = barInstance.data.labels[firstPoint.index];
      const category = barInstance.data.datasets[firstPoint.datasetIndex].label;
      console.log(barInstance.data.datasets[firstPoint.datasetIndex]);
      const value =
        barInstance.data.datasets[firstPoint.datasetIndex].data[
          firstPoint.index
        ];
      emit("onBarClick", { label, category, value });
    }
  } catch (e) {
    console.error(e);
  }
};

const emptyChartPlugin = computed(() => ({
  id: "emptyChart",
  afterDraw(chart) {
    const { datasets } = chart.data;
    let hasData = false;

    for (const dataset of datasets) {
      // set this condition according to your needs
      if (dataset.data.length > 0 && dataset.data.some((item) => item !== 0)) {
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      // type of ctx is CanvasRenderingContext2D
      // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D
      // modify it to your liking
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;

      chart.clear();
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      // if (theme.value === "dark") {
      //   ctx.fillStyle = "rgb(255,255,255)";
      // } else {
      ctx.fillStyle = "rgb(0,0,0)";
      // }
      ctx.fillText(props.emptyMessage, centerX, centerY);
      ctx.restore();
    }
  },
}));

const { barChartProps } = useBarChart({
  plugins: [ChartDataLabels, emptyChartPlugin.value],
  options: {
    tooltips: {
      mode: "dataset",
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1 / 2,
    indexAxis: props.horizontal ? "y" : "x",
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        ...(props.type === "currency" && {
          formatter: function (value) {
            return (
              "€ " +
              value.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
          },
        }),
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.percentages) {
              const index = context.dataIndex;
              return isFinite(context.dataset.percentages?.[index])
                ? `${context.dataset.percentages[index]}%`
                : "n/a";
            }
            if (context.dataset.barOneTotal) {
              return (
                Math.round(
                  (parseInt(context.raw) / context.dataset.barOneTotal) * 100
                ) + "% of total"
              );
            }
            console.log(context);
            return context.raw;
            // return (
            //   "€ " +
            //   context.raw.toLocaleString("nl-NL", {
            //     minimumFractionDigits: 2,
            //     maximumFractionDigits: 2,
            //   })
            // );
          },
        },
      },
    },
    onHover: onBarHover,
    onClick: onBarClick,
    scales: {
      x: {
        grid: {
          drawBorder: !props.horizontal,
          display: props.horizontal,
        },
      },
      y: {
        grid: {
          display: !props.horizontal,
          drawBorder: props.horizontal,
        },
        ticks: {
          // Include a dollar sign in the ticks
          ...(props.type === "currency" && {
            callback: function (value) {
              return (
                "€ " +
                value.toLocaleString("nl-NL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              );
            },
          }),
        },
      },
    },
    layout: {
      padding: {
        right: props.horizontal ? 30 : 0,
        top: props.horizontal ? 0 : 40,
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.chart-wrapper {
  overflow-x: scroll;
}
.totals {
  @media (min-width: 768px) {
    top: 20px;
    right: 25px;
    position: absolute;
  }
  @media (max-width: 768px) {
    margin-top: -20px;
  }
}
</style>
